import React from 'react';
import SnowStorm from 'react-snowstorm';
import './App.css';

const wishes = [
  {
    title: "Boxere",
    img: "boxere.jpg"
  },
  {
    title: "AKG K701",
    img: "https://images-na.ssl-images-amazon.com/images/I/7137lJzhR7L._SL1500_.jpg",
  },
  {
    title: "Gøyal julegenser",
    img: "https://www.tipsyelves.com/mas_assets/cache/image/1/a/2/7/6695/Mens-tacky-tinsel-ugly-christmas-sweater07.Jpg",
  },
  {
    title: "REF Rough Paste",
    img: "https://www.bangerhead.se/bilder/artiklar/zoom/B044192_1.jpg",
  },
  {
    title: "Playstation 3 strømkabel",
    img: "http://img.tradera.net/images/579/250082579_6774686e-7644-445e-885b-e2b493a16b5a.jpg",
  },
]

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.scrollOffset = 0

    this.state = {
      bgTranslation: 0,
    }

    window.addEventListener('scroll', this.handleScroll)
  }

  componentDidMount() {
    // var answer = ""
    // do {
    //   answer = prompt("Hva gjorde Siri i badekaret som liten?")
    // } while(!answer || !(answer.toUpperCase() != "BÆSJA" || answer.toUpperCase() != "BÆSJET"))
  }

  handleScroll = _ => {
      this.setState({bgTranslation: window.pageYOffset})
  }

  render() {
    var wishElements = wishes.map(wish => (
      <div className="itemContainer">
        <h1>{wish.title}</h1>
        <img className="bg" src={wish.img}/>
      </div>
    ))


    return (
      <div className="App">

        <SnowStorm 
          followMouse={false}
          excludeMobile={false}
          snowStick={false}
          vMaxX={0}
          animationInterval={40}
          flakesMax={256}
        />
  
        <div className="contentContainer" style={
          {transform: `translateY(${window.innerHeight - 200}px)` }
        }>
            <h1>Ønskeliste</h1>
            {wishElements}
        </div>
  
        <div className="bgContainer" style={
          {transform: `translateY(${-this.state.bgTranslation/5}px)` }
        }>
          <img className="bg" src="/bg.jpg"/>
        </div>
        <div className="contentFixtureContainer" style={
          {top: window.innerHeight-this.state.bgTranslation/3 - 60}
        }>
        </div>
      </div>
    );
  }
}